import React, { useState, useEffect } from "react";
import { Box, Typography, Button, IconButton } from "@mui/material";
import { useQuery, useMutation } from "@apollo/client";
import { GridColDef } from "@mui/x-data-grid";
import PheonixTable from "../Components/PheonixTable";
import PheonixPaper from "../Components/PheonixPaper";
import ReceiptModal from "../Components/ReceiptModal";
import { TEXT_MESSAGES, Receipt_msg } from "../constant";
import { ReceiptModalFormData } from "../Interfaces/ReceiptModalform";
import dayjs, { Dayjs } from "dayjs";
import { ApolloError } from "@apollo/client";
import {
  GET_RECEIPTS,
  GET_ALL_BORROWERS,
  GET_ALL_VOUCHERS,
  GET_ALL_DEPOSITORS,
  GET_ALL_SHAREHOLDERS,
  GET_LOAN_NUMBERS_BY_BORROWER_RECEIPTTYPE,
  GET_AMOUNT_BY_LOANNO,
  GET_BORROWERS,
  GET_BORROWERS_VOUCHER,
  GET_DEPOSITOR_BY_ID,
} from "../graphql/queries";
import EditIcon from "../assets/EditFilled.png";
import VisibleIcon from "../assets/visibility.png";
import DeleteIcon from "@mui/icons-material/Delete";
import { useMediaQuery, useTheme } from "@mui/material";
import DeleteModal from "../Components/DeleteModal";
import themestyle from "../theme";
import { ADD_RECEIPT, EDIT_RECEIPT, DELETE_RECEIPT } from "../graphql/mutation";
import PheonixSnackBar from "../Components/PheonixSnackBar";
import { Label, PanoramaSharp } from "@mui/icons-material";
import Borrowers from "./Borrowers";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import logo from "../assets/Dlogo.svg";

interface Borrower {
  borrower_id: string;
  borrower_name: string;
}
interface Share {
  shareholder_id: string;
  shareholder_name: string;
}

interface Borrowers {
  [x: string]: any;
  borrower_id: string;
  id: string;
  name: string;
  address1: string;
  address2?: string;
}

const ReceiptScreen: React.FC = () => {
  const today = dayjs();
  const [modalOpen, setModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isTextEditable, setIsTextEditable] = useState(false);
  const [selectedBorrowerId, setSelectedBorrowerId] = useState<string | null>(
    null
  );
  const [Idforloan, setIdforloan] = useState<string | null>(null);
  const [tableData, setTableData] = useState<ReceiptModalFormData[]>([]);
  const [tableCount, setTableCount] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [title, setTitle] = useState("");
  const [dataLoading, setDataLoading] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [entityToDelete, setEntityToDelete] = useState<string | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [entityToDisplay, setEntityToDisplay] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "error"
  );
  const [paginationModel, setPaginationModel] = useState(() => {
    const storedPagination = localStorage.getItem("ReceiptpaginationModel");
    return storedPagination
      ? JSON.parse(storedPagination)
      : { pageSize: 5, page: 0 };
  });

  useEffect(() => {
    localStorage.setItem(
      "ReceiptpaginationModel",
      JSON.stringify(paginationModel)
    );
    return () => {
      localStorage.removeItem("ReceiptpaginationModel");
    };
  }, [paginationModel]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>({});
  const isWideScreen = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const isMobileSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const { data, refetch } = useQuery(GET_ALL_BORROWERS);

  const [addReceiptMutation] = useMutation(ADD_RECEIPT);
  const [editReceiptMutation] = useMutation(EDIT_RECEIPT);
  const [deleteReceiptMutation] = useMutation(DELETE_RECEIPT);

  const {
    loading: customerLoading,
    error: customerError,
    data: customerData,
    refetch: receiptRefetch,
  } = useQuery(GET_RECEIPTS, {
    variables: {
      page: paginationModel.page,
      perPage: paginationModel.pageSize,
      searchQuery: searchQuery || "",
    },
    onCompleted: (data) => {
      const { totalPages, totalCount, receipt } = data.getReceiptperPage;
      const startIndex = paginationModel.page * paginationModel.pageSize + 1;
      const receiptsWithSerialNo = receipt.map(
        (receipt: ReceiptModalFormData, index: number) => ({
          ...receipt,
          serial_no: (startIndex + index).toString(),
        })
      );
      setTableData(receiptsWithSerialNo);
      setTableCount(totalCount);
      setTotalPages(totalPages);
    },
  });

  type RoleType = "Borrower" | "Depositor" | "Shareholder";

  const roleTypes: RoleType[] = ["Borrower", "Depositor", "Shareholder"];

  const {
    loading: borrowerLoading,
    error: borrowerError,
    data: borrowerData,
    refetch: borrowerRefetch,
  } = useQuery(GET_BORROWERS_VOUCHER);
  const {
    loading: depositorLoading,
    error: depositorError,
    data: depositorData,
    refetch: depositorRefetch,
  } = useQuery(GET_ALL_DEPOSITORS);
  const {
    loading: shareholderLoading,
    error: shareholderError,
    data: shareholderData,
    refetch: shareholderRefetch,
  } = useQuery(GET_ALL_SHAREHOLDERS);
  const {
    loading: voucherLoading,
    error: voucherError,
    data: voucherData,
    refetch: voucherRefetch,
  } = useQuery(GET_ALL_VOUCHERS);

  useEffect(() => {
    setDataLoading(true);

    if (!customerLoading && !customerError && customerData) {
      const { totalPages, totalCount, receipt } =
        customerData.getReceiptperPage;
      const startIndex = paginationModel.page * paginationModel.pageSize + 1;

      const receiptsWithSerialNo = receipt.map(
        (receipt: ReceiptModalFormData, index: number) => ({
          ...receipt,
          serial_no: (startIndex + index).toString(),
        })
      );

      setTableData(receiptsWithSerialNo);
      setTableCount(totalCount);
      setTotalPages(totalPages);
      setDataLoading(false);
    }
  }, [
    customerData,
    customerLoading,
    customerError,
    paginationModel.page,
    paginationModel.pageSize,
  ]);

  const [receiptFormData1, setReceiptFormData1] =
    useState<ReceiptModalFormData>({
      Name: "",
      "Borrower ID": "",
      "Loan No": "",
      "Receipt Type": "",
      Amount: "",
      "Select Receipt role type": "",
      Remarks: "",
    });
  const [loanNumber, setLoanNumber] = useState<string[]>([]);
  interface Loan {
    loan_no: string;
    loan_type: string;
  }
  const [selectedBorrower, setSelectedBorrower] = useState<string>("");
  const [allloans, setallloans] = useState<Loan[]>([]);
  const {
    loading: loannoLoading,
    error: loannoError,
    data: loannoData,
    refetch: refetchLoanNumbers,
  } = useQuery(GET_LOAN_NUMBERS_BY_BORROWER_RECEIPTTYPE, {
    variables: {
      borrowerId: receiptFormData1["Borrower Name"],
      receipt_type: receiptFormData1["Receipt Type"],
    },
    skip: !receiptFormData1["Receipt Type"],
    onCompleted: (data) => {
      if (data && data.getLoanNumbersByBorrowerandReceipttype) {
        setallloans(data.getLoanNumbersByBorrowerandReceipttype);
        setLoanNumber(
          data.getLoanNumbersByBorrowerandReceipttype.map(
            (loan: { loan_no: string }) => loan.loan_no
          )
        );
      }
    },
  });
  useEffect(() => {
    console.error("Query Variables:", {
      borrowerId: receiptFormData1["Borrower Name"],
      receipt_type: receiptFormData1["Receipt Type"],
    });
  }, [receiptFormData1]);

  const [depositamount, setDepositamount] = useState<number | null>(null);
  const [shareamount, setShareamount] = useState<number | null>(null);
  const {
    loading: depositLoading,
    error: depositError,
    data: depositData,
    refetch: refetchdeposit,
  } = useQuery(GET_DEPOSITOR_BY_ID, {
    variables: { depositor_id: receiptFormData1["Depositor ID"] },
    skip: !receiptFormData1["Depositor ID"],
    onCompleted: (data) => {
      setDepositamount(data.getDepositorbyIDDetails.total_interest_paid);
    },
  });
  const [selectedLoanNo, setSelectedLoanNo] = useState<number | null>(null);
  const [amount, setAmount] = useState<number | null>(null);
  const {
    loading: amountLoading,
    error: amountError,
    data: amountData,
    refetch: refetchAmount,
  } = useQuery(GET_AMOUNT_BY_LOANNO, {
    variables: {
      borrowerId: receiptFormData1["Borrower Name"],
      loanNo: selectedLoanNo || -1,
    },
    skip: !selectedLoanNo || !receiptFormData1["Borrower Name"],
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data.getAmountByBorrowerAndLoanNo) {
        setAmount(data.getAmountByBorrowerAndLoanNo.amount);
      }
    },
  });

  useEffect(() => {
    if (receiptFormData1["Depositor ID"]) {
      refetchdeposit().then(({ data }) => {
        setDepositamount(data.getDepositorbyIDDetails);
      });
    }
  }, [receiptFormData1["Depositor ID"], refetchdeposit]);

  useEffect(() => {
    const loanNo = receiptFormData1["Loan No"];
    if (loanNo) {
      const loanNoNumber = parseInt(loanNo, 10);
      if (!isNaN(loanNoNumber)) {
        setSelectedLoanNo(loanNoNumber);
      }
    }
  }, [receiptFormData1["Loan No"]]);

  useEffect(() => {
    refetchLoanNumbers();
  }, [receiptFormData1["Receipt Type"]]);

  useEffect(() => {
    if (selectedLoanNo) {
      refetchAmount();
    }
  }, [selectedLoanNo, refetchAmount]);

  const handleLoanNumberChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const loanNo = event.target.value as number;
    setSelectedLoanNo(loanNo);
  };

  useEffect(() => {
    receiptRefetch();
    borrowerRefetch();
    depositorRefetch();
    shareholderRefetch();
    voucherRefetch();
  }, [
    receiptRefetch,
    borrowerRefetch,
    depositorRefetch,
    shareholderRefetch,
    voucherRefetch,
  ]);
  const [fields, setFields] = useState([
    { label: "Select Receipt role type", type: "text" },
    { label: "Borrower Name", type: "text" },
    { label: "Receipt Type", type: "text" },
    { label: "Loan No", type: "text" },
    { label: "Date", type: "text" },
    { label: "Amount", type: "text" },
    { label: "Remarks", type: "text" },
  ]);

  const handleRoleTypeChange = (roleType: RoleType) => {
    let updatedFields = [...fields];

    switch (roleType) {
      case "Borrower":
        updatedFields = updatedFields.map((field) =>
          field.label === "Depositor Name" || field.label === "Shareholder Name"
            ? { ...field, label: "Borrower Name" }
            : field
        );
        break;
      case "Depositor":
        updatedFields = updatedFields.map((field) =>
          field.label === "Borrower Name" || field.label === "Shareholder Name"
            ? { ...field, label: "Depositor Name" }
            : field
        );
        break;
      case "Shareholder":
        updatedFields = updatedFields.map((field) =>
          field.label === "Borrower Name" || field.label === "Depositor Name"
            ? { ...field, label: "Shareholder Name" }
            : field
        );
        break;
      default:
        break;
    }
    setFields(updatedFields);
  };

  const handlePaginationChange = (newModel: any) => {
    setPaginationModel((prevModel: any) => ({
      ...prevModel,
      ...newModel,
    }));
  };
  const [add, setisadd] = useState(false);
  const handleOpenModal = (
    selectedRowData: ReceiptModalFormData | null = null
  ) => {
    setModalOpen(true);

    setFieldErrors({});
    if (selectedRowData) {
      if (selectedRowData.role_type === "Borrower") {
        const selectedBorrower1 = borrowerData.getAllBorrowers.find(
          (borrower: Borrower) =>
            borrower.borrower_id === selectedRowData.name_id
        );
        if (selectedBorrower1) {
          setSelectedBorrower(selectedBorrower1.borrower_name);
          setReceiptFormData1({
            Name: selectedBorrower1.borrower_name,
            "Borrower Name": selectedRowData.name_id,
            "Borrower ID": selectedRowData.name_id,
            "Loan No": selectedRowData.loan_no,
            "Receipt Type": selectedRowData.receipt_type,
            Amount: selectedRowData.amount,
            "Select Receipt role type": selectedRowData.role_type,
            Remarks: selectedRowData.remarks,
          });
        } else {
          setSelectedBorrower(selectedRowData.name);
          setReceiptFormData1({
            Name: selectedRowData.name,
            "Borrower Name": selectedRowData.name_id,
            "Borrower ID": selectedRowData.name_id,
            "Loan No": selectedRowData.loan_no,
            "Receipt Type": selectedRowData.receipt_type,
            Amount: selectedRowData.amount,
            "Select Receipt role type": selectedRowData.role_type,
            Remarks: selectedRowData.remarks,
          });
        }
      } else if (selectedRowData.role_type === "Depositor") {
        const selectedBorrower1 = depositorData.getAllDepositors.find(
          (borrower: any) => borrower.depositor_id === selectedRowData.name_id
        );
        if (selectedBorrower1) {
          setReceiptFormData1({
            Name: selectedBorrower1.depositor_name,
            "Borrower Name": selectedRowData.name_id,
            "Borrower ID": selectedRowData.name_id,
            "Loan No": selectedRowData.loan_no,
            "Receipt Type": selectedRowData.receipt_type,
            Amount: selectedRowData.amount,
            "Select Receipt role type": selectedRowData.role_type,
            Remarks: selectedRowData.remarks,
          });
        } else {
          setReceiptFormData1({
            Name: selectedRowData.name,
            "Borrower Name": selectedRowData.name_id,
            "Borrower ID": selectedRowData.name_id,
            "Loan No": selectedRowData.loan_no,
            "Receipt Type": selectedRowData.receipt_type,
            Amount: selectedRowData.amount,
            "Select Receipt role type": selectedRowData.role_type,
            Remarks: selectedRowData.remarks,
          });
        }
      } else {
        const selectedBorrower1 = shareholderData.getAllShareholder.find(
          (borrower: any) => borrower.shareholder_id === selectedRowData.name_id
        );
        if (selectedBorrower1) {
          setReceiptFormData1({
            Name: selectedBorrower1.shareholder_name,
            "Borrower Name": selectedRowData.name_id,
            "Borrower ID": selectedRowData.name_id,
            "Loan No": selectedRowData.loan_no,
            "Receipt Type": selectedRowData.receipt_type,
            Amount: selectedRowData.amount,
            "Select Receipt role type": selectedRowData.role_type,
            Remarks: selectedRowData.remarks,
          });
        } else {
          setReceiptFormData1({
            Name: selectedRowData.name,
            "Borrower Name": selectedRowData.name_id,
            "Borrower ID": selectedRowData.name_id,
            "Loan No": selectedRowData.loan_no,
            "Receipt Type": selectedRowData.receipt_type,
            Amount: selectedRowData.amount,
            "Select Receipt role type": selectedRowData.role_type,
            Remarks: selectedRowData.remarks,
          });
        }
      }
      setSelectedBorrowerId(selectedRowData.id);
      setIdforloan(selectedRowData.name_id);
      setIsEditing(true);
      setIsTextEditable(false);
      const parsedDate = dayjs(selectedRowData.uptodate, "DD-MM-YYYY");

      setSelectedDate2(parsedDate.isValid() ? parsedDate : dayjs());
      setTitle(Receipt_msg.EDIT_RECEIPT);
    } else {
      setisadd(true);
      setSelectedBorrowerId(null);
      setIsEditing(false);
      setIsTextEditable(false);
      setReceiptFormData1({
        Name: "",
        "Borrower ID": "",
        "Loan No": "",
        "Receipt Type": "",
        "Select Receipt role type": "",
        Amount: "",
        Remarks: "",
      });
      setSelectedDate2(dayjs());
      setTitle(Receipt_msg.MODAL_TITLE);
    }
  };

  function numberToIndianWords(num: number): string {
    const units = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
    ];
    const teens = [
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const tens = [
      "",
      "",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];
    const thousands = ["", "Thousand", "Lakh", "Crore"];

    if (num === 0) return "Zero";

    const convert = (n: number): string => {
      if (n < 10) return units[n];
      if (n < 20) return teens[n - 10];
      if (n < 100)
        return tens[Math.floor(n / 10)] + (n % 10 ? " " + units[n % 10] : "");
      if (n < 1000)
        return (
          units[Math.floor(n / 100)] +
          " Hundred" +
          (n % 100 ? " and " + convert(n % 100) : "")
        );
      if (n < 100000)
        return (
          convert(Math.floor(n / 1000)) +
          " Thousand" +
          (n % 1000 ? " " + convert(n % 1000) : "")
        );
      if (n < 10000000)
        return (
          convert(Math.floor(n / 100000)) +
          " Lakh" +
          (n % 100000 ? " " + convert(n % 100000) : "")
        );
      if (n < 1000000000000) {
        return (
          convert(Math.floor(n / 10000000)) +
          " Crore" +
          (n % 10000000 ? " " + convert(n % 10000000) : "")
        );
      }
      return (
        convert(Math.floor(n / 100000000000)) +
        " Lakh Crore" +
        (n % 100000000000 ? " " + convert(n % 100000000000) : "")
      );
    };

    return convert(num).trim() + " Rupees";
  }
  const [borrowers, setBorrowers] = useState<Borrowers[]>([]);
  useEffect(() => {
    if (data && data.getAllBorrowers) {
      setBorrowers(data.getAllBorrowers);
    }
  }, [data]);
  const handleDownload = (row: ReceiptModalFormData) => {
    if (row) {
      depositorRefetch();
      shareholderRefetch();
      if (row.role_type === "Borrower") {
        const borrower = borrowers.find(
          (b: any) => b.borrower_id === row.name_id
        );
        if (borrower) {
          generatePDF(row, borrower);
          return;
        }
      } else if (row.role_type === "Depositor") {
        const depositor = depositorData.getAllDepositors.find(
          (d: any) => d.depositor_id === row.name_id
        );
        if (depositor) {
          generatePDF(row, depositor);
          return;
        }
      } else if (row.role_type === "Shareholder") {
        const shareholder = shareholderData.getAllShareholder.find(
          (s: any) => s.shareholder_id === row.name_id
        );
        if (shareholder) {
          generatePDF(row, shareholder);
          return;
        }
      }
    }
  };

  const generatePDF = (row: ReceiptModalFormData, data: any) => {
    const doc = new jsPDF();
    const pngImage = new Image();
    pngImage.src = logo;
    pngImage.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = pngImage.width;
      canvas.height = pngImage.height;
      if (ctx) {
        ctx.drawImage(pngImage, 0, 0, canvas.width, canvas.height);
      }
      const imgData = canvas.toDataURL("image/png", 1.0);
      const pdfImageWidth = 50;
      const pdfImageHeight = (pngImage.height / pngImage.width) * pdfImageWidth;
      doc.addImage(imgData, "PNG", 14, 10, pdfImageWidth, pdfImageHeight);
      doc.setFont("Helvetica", "Bold");
      doc.setFontSize(14);
      doc.text(`Receipt Copy of ` + row.role_type, 130, 20);
      doc.setLineWidth(0.5);
      doc.line(14, 35, 196, 35);
      let yOffset = 35;
      yOffset += 8;
      doc.setFont("Helvetica", "normal");
      doc.text("Receipt No : ", 15, yOffset);
      doc.setFont("Helvetica", "bold");
      doc.text(String(row.receipt_no), 45, yOffset);
      doc.setFont("Helvetica", "normal");
      doc.text("Date:", 157, yOffset);
      doc.setFont("Helvetica", "bold");
      doc.text(String(row.uptodate), 170, yOffset);
      yOffset += 8;
      doc.setFont("Helvetica", "normal");
      doc.text("Name :", 15, yOffset);
      doc.setFont("Helvetica", "bold");
      doc.text(String(row.name), 33, yOffset);
      yOffset += 8;
      if (data.address1 && data.address1.length > 0) {
        doc.setFont("Helvetica", "normal");
        doc.text("Address :", 15, yOffset);
        doc.setFont("Helvetica", "bold");
        const pageWidth = doc.internal.pageSize.width;
        const marginLeft = 38;
        const textWidth = pageWidth - marginLeft - 15;
        const wrappedAddress = doc.splitTextToSize(
          String(data.address1),
          textWidth
        );
        wrappedAddress.forEach((line: string) => {
          doc.text(line, marginLeft, yOffset);
          yOffset += 8;
        });
      }
      if (row.role_type === "Borrower") {
        doc.setFont("Helvetica", "normal");
        doc.text("Loan Type :", 15, yOffset);
        doc.setFont("Helvetica", "bold");
        doc.text(row.receipt_type, 43, yOffset);
        yOffset += 8;
      }
      doc.setFont("Helvetica", "bold");
      let tableColumn;
      let tableRows: any[];
      if (row.role_type === "Borrower") {
        tableColumn = ["Borrower Loan No", "Particulars", "Amount"];
        tableRows = [[row.loan_no, row.receipt_type, row.amount]];
      } else {
        tableColumn = ["Receipt No", "Particulars", "Amount"];
        tableRows = [[row.receipt_no, row.remarks, row.amount]];
      }
      (doc as any).autoTable({
        startY: yOffset - 2,
        head: [tableColumn],
        body: tableRows,
        theme: "plain",
        styles: {
          halign: "left",
          font: "Helvetica",
          fontStyle: "normal",
          textColor: [0, 0, 0],
          fontSize: 12,
          fillColor: [240, 240, 240],
        },
        alternateRowStyles: {
          fillColor: [255, 255, 255],
        },
        headStyles: {
          fillColor: [240, 240, 240],
          font: "Helvetica",
          fontStyle: "Bold",
          textColor: [0, 0, 0],
          fontSize: 14,
          halign: "left",
        },
        columnStyles:
          row.role_type === "Depositor" || row.role_type === "Shareholder"
            ? {
                0: { cellWidth: 30 },
                1: { cellWidth: 110 },
                2: { cellWidth: 30 },
              }
            : {},
        tableLineColor: [255, 255, 255],
        tableLineWidth: 0,
        didDrawPage: (data: any) => {
          yOffset = data.cursor.y;
        },
      });
      yOffset += 8;
      doc.setFont("Helvetica", "normal");
      if (row.role_type === "Borrower") {
        doc.text("Loan Amount:", 15, yOffset);
        doc.setFont("Helvetica", "bold");
        const loanAmountInWords = numberToIndianWords(Number(row.amount));
        const pageWidth = doc.internal.pageSize.width;
        const marginLeft = 50;
        const textWidth = pageWidth - marginLeft - 15;
        const wrappedLoanAmount = doc.splitTextToSize(
          loanAmountInWords,
          textWidth
        );
        wrappedLoanAmount.forEach((line: string) => {
          doc.text(line, marginLeft, yOffset);
          yOffset += 8;
        });
      } else if (row.role_type === "Depositor") {
        doc.text("Deposit Amount:", 15, yOffset);
        doc.setFont("Helvetica", "bold");
        const depositorAmountInWords = numberToIndianWords(Number(row.amount));
        const pageWidth = doc.internal.pageSize.width;
        const marginLeft = 58;
        const textWidth = pageWidth - marginLeft - 15;
        const wrappedDepositorAmount = doc.splitTextToSize(
          depositorAmountInWords,
          textWidth
        );
        wrappedDepositorAmount.forEach((line: string) => {
          doc.text(line, marginLeft, yOffset);
          yOffset += 8;
        });
      } else if (row.role_type === "Shareholder") {
        doc.text("Share Amount:", 15, yOffset);
        doc.setFont("Helvetica", "bold");
        const shareholderAmountInWords = numberToIndianWords(
          Number(row.amount)
        );
        const pageWidth = doc.internal.pageSize.width;
        const marginLeft = 65;
        const textWidth = pageWidth - marginLeft - 15;
        const wrappedShareholderAmount = doc.splitTextToSize(
          shareholderAmountInWords,
          textWidth
        );
        wrappedShareholderAmount.forEach((line: string) => {
          doc.text(line, marginLeft, yOffset);
          yOffset += 8;
        });
      }
      doc.save(`Receipt Copy of ` + row.role_type);
    };
  };
  const handleViewModal = (
    selectedRowData: ReceiptModalFormData | null = null
  ) => {
    setModalOpen(true);
    setFieldErrors({});
    if (selectedRowData) {
      if (selectedRowData.role_type === "Borrower") {
        const selectedBorrower1 = borrowerData.getAllBorrowers.find(
          (borrower: Borrower) =>
            borrower.borrower_id === selectedRowData.name_id
        );
        if (selectedBorrower1) {
          setSelectedBorrower(selectedBorrower1.borrower_name);
          setReceiptFormData1({
            Name: selectedBorrower1.borrower_name,
            "Borrower Name": selectedRowData.name_id,
            "Borrower ID": selectedRowData.name_id,
            "Loan No": selectedRowData.loan_no,
            "Receipt Type": selectedRowData.receipt_type,
            Amount: selectedRowData.amount,
            "Select Receipt role type": selectedRowData.role_type,
            Remarks: selectedRowData.remarks,
          });
        } else {
          setSelectedBorrower(selectedRowData.name);
          setReceiptFormData1({
            Name: selectedRowData.name,
            "Borrower Name": selectedRowData.name_id,
            "Borrower ID": selectedRowData.name_id,
            "Loan No": selectedRowData.loan_no,
            "Receipt Type": selectedRowData.receipt_type,
            Amount: selectedRowData.amount,
            "Select Receipt role type": selectedRowData.role_type,
            Remarks: selectedRowData.remarks,
          });
        }
      } else if (selectedRowData.role_type === "Depositor") {
        const selectedBorrower1 = depositorData.getAllDepositors.find(
          (borrower: any) => borrower.depositor_id === selectedRowData.name_id
        );
        if (selectedBorrower1) {
          setReceiptFormData1({
            Name: selectedBorrower1.depositor_name,
            "Borrower Name": selectedRowData.name_id,
            "Borrower ID": selectedRowData.name_id,
            "Loan No": selectedRowData.loan_no,
            "Receipt Type": selectedRowData.receipt_type,
            Amount: selectedRowData.amount,
            "Select Receipt role type": selectedRowData.role_type,
            Remarks: selectedRowData.remarks,
          });
        } else {
          setReceiptFormData1({
            Name: selectedRowData.name,
            "Borrower Name": selectedRowData.name_id,
            "Borrower ID": selectedRowData.name_id,
            "Loan No": selectedRowData.loan_no,
            "Receipt Type": selectedRowData.receipt_type,
            Amount: selectedRowData.amount,
            "Select Receipt role type": selectedRowData.role_type,
            Remarks: selectedRowData.remarks,
          });
        }
      } else {
        const selectedBorrower1 = shareholderData.getAllShareholder.find(
          (borrower: any) => borrower.shareholder_id === selectedRowData.name_id
        );
        if (selectedBorrower1) {
          setReceiptFormData1({
            Name: selectedBorrower1.shareholder_name,
            "Borrower Name": selectedRowData.name_id,
            "Borrower ID": selectedRowData.name_id,
            "Loan No": selectedRowData.loan_no,
            "Receipt Type": selectedRowData.receipt_type,
            Amount: selectedRowData.amount,
            "Select Receipt role type": selectedRowData.role_type,
            Remarks: selectedRowData.remarks,
          });
        } else {
          setReceiptFormData1({
            Name: selectedRowData.name,
            "Borrower Name": selectedRowData.name_id,
            "Borrower ID": selectedRowData.name_id,
            "Loan No": selectedRowData.loan_no,
            "Receipt Type": selectedRowData.receipt_type,
            Amount: selectedRowData.amount,
            "Select Receipt role type": selectedRowData.role_type,
            Remarks: selectedRowData.remarks,
          });
        }
      }
      setSelectedBorrowerId(selectedRowData.id);
      setIsTextEditable(true);
      const parsedDate = dayjs(selectedRowData.uptodate, "DD-MM-YYYY");

      setSelectedDate2(parsedDate.isValid() ? parsedDate : dayjs());
      setTitle(Receipt_msg.VIEW_RECEIPT_TITLE);
    }
  };

  const handleCloseModal = () => {
    setFieldErrors({});
    setReceiptFormData1({
      Name: "",
      "Borrower ID": "",
      "Loan No": "",
      "Receipt Type": "",
      Amount: "",
      "Select Receipt role type": "",
      Remarks: "",
    });
    setallloans([]);
    setLoanNumber([]);
    setModalOpen(false);
    setAmount(null);
    setSelectedLoanNo(null);
  };
  const handleDeleteEntity = async () => {
    try {
      await deleteReceiptMutation({
        variables: {
          id: entityToDelete,
        },
      });
      setDeleteModalOpen(false);
      setSnackbarOpen(true);
      setSnackbarSeverity("error");
      setSnackbarMessage(Receipt_msg.DELETE_SUCCESS_receipt);
      receiptRefetch();
    } catch (error) {
      console.error("Error deleting receipt:", error);
      setDeleteModalOpen(false);
      setSnackbarOpen(true);
      setSnackbarSeverity("error");
      setSnackbarMessage(Receipt_msg.FAILED_DELETE);
    }
  };
  const [selectedDate2, setSelectedDate2] = useState<Dayjs | null>(dayjs());
  const currentDate = new Date().toISOString().slice(0, 10);
  const handleAdd = async () => {
    setFieldErrors({});
    const amount = parseFloat(receiptFormData1["Amount"]);
    const loanNo = parseInt(receiptFormData1["Loan No"], 10);
    const borrowerInput = {
      role_type: receiptFormData1["Select Receipt role type"],
      name_id:
        receiptFormData1[
          "Borrower Name" || "Depositor Name" || "Shareholder Name"
        ],
      uptodate: selectedDate2
        ? dayjs(selectedDate2).format("DD-MM-YYYY")
        : null,
      loan_no: loanNo,
      receipt_type: receiptFormData1["Receipt Type"],
      amount: amount,
      remarks: receiptFormData1["Remarks"],
    };

    const loans = voucherData.getAllVouchers.filter(
      (loan: any) => loan.loan_no === loanNo
    );
    let loanDateStr = loans[0]?.date;
    let receiptDateStr = dayjs(selectedDate2).format("DD-MM-YYYY");
    const parsedLoanDate = dayjs(loanDateStr, "DD/MM/YYYY");
    const parsedReceiptDate = dayjs(receiptDateStr, "DD/MM/YYYY");

    if (parsedReceiptDate < parsedLoanDate) {
      setSnackbarMessage(Receipt_msg.DATE_INVALID);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } else {
      try {
        if (isEditing && selectedBorrowerId) {
          await editReceiptMutation({
            variables: {
              id: selectedBorrowerId,
              ...borrowerInput,
            },
          });
          setSnackbarMessage(Receipt_msg.RECEIPTUPDATE);
          setSnackbarSeverity("success");
        } else {
          await addReceiptMutation({
            variables: borrowerInput,
          });
          setSnackbarMessage(Receipt_msg.RECEIPTCREATE);
          setSnackbarSeverity("success");
        }
        receiptRefetch();
        setSnackbarOpen(true);
        handleCloseModal();
      } catch (error: any) {
        const apiErrorMessage =
          error?.graphQLErrors?.[0]?.message || TEXT_MESSAGES.ADD_EDIT_ERROR;
        setSnackbarMessage(apiErrorMessage);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        console.error(error);
      }
    }
  };

  const handleSearchQueryChange = (newQuery: string) => {
    setSearchQuery(newQuery);
    if (newQuery.trim() === "") {
      receiptRefetch({ variables: { searchQuery: {} } });
    } else {
      receiptRefetch();
    }
  };
  const handleDeleteClick = (receiptId: string, receiptNo: string) => {
    setEntityToDelete(receiptId);
    setEntityToDisplay(receiptNo);
    setDeleteModalOpen(true);
  };
  const handleDelete = async (receiptId: string) => {
    try {
      const result = await deleteReceiptMutation({
        variables: {
          id: receiptId,
        },
      });
      if (result && result.data && result.data.deleteReceipt) {
        setSnackbarMessage(Receipt_msg.DELETE_SUCCESS_receipt);
        setSnackbarOpen(true);
        receiptRefetch();
      }
    } catch (error) {
      console.error("Error during deletion:", error);
    }
  };
  const confirmDelete = async () => {
    if (!entityToDelete) return;

    try {
      const result = await deleteReceiptMutation({
        variables: {
          id: entityToDelete,
        },
      });

      if (result && result.data && result.data.deleteReceipt) {
        setSnackbarMessage(Receipt_msg.DELETE_SUCCESS_receipt);
        setSnackbarSeverity("error");
        receiptRefetch();
      }
    } catch (error) {
      setSnackbarMessage(TEXT_MESSAGES.DELETE_ERROR);
      setSnackbarSeverity("error");
      console.error("Error during deletion:", error);
    } finally {
      setSnackbarOpen(true);
      setDeleteModalOpen(false);
      setEntityToDelete(null);
    }
  };
  const requiredFields = [
    "Select Receipt role type",
    "Borrower Name",
  ] as (keyof ReceiptModalFormData)[];
  const columns: GridColDef[] = [
    {
      field: "serial_no",
      headerName: "S.No",
      width: 120,
    },
    {
      field: "uptodate",
      headerName: "Date",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "receipt_no",
      headerName: "Receipt No",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "loan_no",
      headerName: "Loan No",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "receipt_type",
      headerName: "Receipt Type",
      flex: 1,
      minWidth: isMobileSmall ? 250 : 200,
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      minWidth: 200,
    },

    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 150,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "left",
            marginLeft: "-8px",
            marginTop: "8px",
          }}
        >
          <IconButton
            aria-label="view"
            onClick={() => handleViewModal(params.row as ReceiptModalFormData)}
          >
            <img
              src={VisibleIcon}
              alt="VisibleIcon"
              style={{ width: "20px", height: "20px" }}
            />
          </IconButton>
          <IconButton
            aria-label="edit"
            onClick={() => handleOpenModal(params.row as ReceiptModalFormData)}
          >
            <img src={EditIcon} alt="EditIcon" />
          </IconButton>
          <IconButton
            sx={{ color: "#208BEE" }}
            onClick={() => handleDownload(params.row)}
          >
            <FileDownloadOutlinedIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={() =>
              handleDeleteClick(params.row.id, params.row.receipt_no)
            }
          >
            <DeleteIcon
              style={{
                width: "20px",
                height: "20px",
                color: themestyle.colors.primary,
              }}
            />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Box style={{ padding: "15px" }}>
        <PheonixSnackBar
          open={snackbarOpen}
          message={snackbarMessage}
          severity={snackbarSeverity}
          onClose={() => setSnackbarOpen(false)}
          vertical="top"
          horizontal="center"
        />
        {isWideScreen ? (
          <PheonixPaper
            style={{
              borderRadius: "20px",
              height: "70px",
              marginBottom: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                textAlign: "center",
                padding: isMobileSmall ? "15px 0" : "15px 30px",
              }}
            >
              <Typography
                style={{
                  color: themestyle.colors.black1,
                  fontWeight: themestyle.fontWeight.sm,
                  fontSize: isMobileSmall
                    ? themestyle.fontSizes.sm
                    : themestyle.fontSizes.medium,
                }}
              >
                {Receipt_msg.RECEIPT}
              </Typography>
              <Button
                style={{
                  background: themestyle.colors.primary,
                  color: themestyle.colors.white,
                  width: "160px",
                }}
                onClick={() => handleOpenModal(null)}
              >
                {Receipt_msg.ADD_RECEIPT}
              </Button>
            </div>
          </PheonixPaper>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              textAlign: "center",
              padding: isMobileSmall ? "15px 0" : "15px 30px",
            }}
          >
            <Typography
              style={{
                color: themestyle.colors.black1,
                marginRight: "10px",
                fontWeight: themestyle.fontWeight.sm,
                fontSize: isMobileSmall
                  ? themestyle.fontSizes.sm
                  : themestyle.fontSizes.medium,
              }}
            >
              {Receipt_msg.RECEIPT}
            </Typography>
            <Button
              style={{
                background: themestyle.colors.primary,
                color: themestyle.colors.white,
                width: isWideScreen ? "142px" : "160px",
              }}
              onClick={() => handleOpenModal(null)}
            >
              {Receipt_msg.ADD_RECEIPT}
            </Button>
          </div>
        )}
        <PheonixPaper style={{ borderRadius: "20px" }}>
          <div style={{ width: "100%", overflowX: "auto" }}>
            <PheonixTable
              columns={columns}
              rows={tableData}
              rowCount={tableCount}
              paginationMode="server"
              paginationModel={paginationModel}
              loading={dataLoading}
              onPaginationModelChange={handlePaginationChange}
              searchQuery={searchQuery}
              onSearchQueryChange={handleSearchQueryChange}
            />
          </div>
        </PheonixPaper>
        <ReceiptModal
          open={modalOpen}
          onClose={handleCloseModal}
          onAdd={handleAdd}
          title={title}
          fields={fields}
          add={add}
          loanNumbers={loanNumber}
          refetchLoanNumbers={refetchLoanNumbers}
          amountdata={amount}
          depositamountdata={depositamount}
          refetchDepositamount={refetchdeposit}
          refetchAmount={refetchAmount}
          formData={receiptFormData1}
          setFormData={setReceiptFormData1}
          fieldErrors={fieldErrors}
          setFieldErrors={setFieldErrors}
          roleTypes={roleTypes}
          context="Receipt"
          editable={isTextEditable}
          borrowers={borrowerData ? borrowerData.getAllBorrowers : []}
          depositors={depositorData ? depositorData.getAllDepositors : []}
          shareholders={
            shareholderData ? shareholderData.getAllShareholder : []
          }
          vouchers={voucherData ? voucherData.getAllVouchers : []}
          onRoleTypeChange={handleRoleTypeChange}
          selectedDate={selectedDate2}
          setSelectedDate={setSelectedDate2}
        />
        <DeleteModal
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          onConfirm={confirmDelete}
          entity={entityToDisplay}
          entityname="Receipt"
        />
      </Box>
    </div>
  );
};
export default ReceiptScreen;
