import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
  Grid,
  Tab,
  Tabs,
  CircularProgress,
} from "@mui/material";
import { useQuery, useMutation } from "@apollo/client";
import PheonixPaper from "../Components/PheonixPaper";
import { VoucherFormData } from "../Interfaces/VoucherFormdataProps";
import { GET_VOUCHERS_LOANTYPE } from "../graphql/queries";
import PheonixTable from "../Components/PheonixTable";
import themestyle from "../theme";
import { TEXT_MESSAGES } from "../constant";
import { useNavigate, useLocation } from "react-router-dom";
import { GridColDef } from "@mui/x-data-grid";
const Loanledger: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isWideScreen = useMediaQuery("(min-width:600px)");
  const isMobileSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };
  const [tableData, setTableData] = useState<VoucherFormData[]>([]);
  const [monthlyVouchers, setMonthlyVouchers] = useState<VoucherFormData[]>([]);
  const [otherVouchers, setOtherVouchers] = useState<VoucherFormData[]>([]);
  const [tableCount, setTableCount] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [title, settitle] = useState("");
  const [dataLoading, setdataLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "error"
  );
  const [succMessage, setSuccMessage] = useState("");
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0,
  });
  const {
    loading: customerLoading,
    error: customerError,
    data: customerData,
    refetch: voucherRefetch,
  } = useQuery(GET_VOUCHERS_LOANTYPE, {
    variables: {
      page: paginationModel.page,
      perPage: paginationModel.pageSize,
      searchQuery: searchQuery || "",
      loan_type: selectedTab === 0 ? "Monthly" : "Daily",
    },
    onCompleted: (data) => {
      const { totalPages, totalCount, voucher } = data.getBorrowersbyLoanType;
      const startIndex = paginationModel.page * paginationModel.pageSize + 1;
      const vouchersWithSerialNo = voucher.map(
        (voucher: VoucherFormData, index: number) => ({
          ...voucher,
          serial_no: (startIndex + index).toString(),
        })
      );
      setTableData(vouchersWithSerialNo);
      setTableCount(totalCount);
      setTotalPages(totalPages);
    },
  });
  useEffect(() => {
    setdataLoading(true);
    if (!customerLoading && !customerError && customerData) {
      const { totalPages, totalCount, voucher } =
        customerData.getBorrowersbyLoanType;
      const startIndex = paginationModel.page * paginationModel.pageSize + 1;
      const vouchersWithSerialNo = voucher.map(
        (voucher: VoucherFormData, index: number) => ({
          ...voucher,
          serial_no: (startIndex + index).toString(),
        })
      );
      setTableData(vouchersWithSerialNo);
      setTableCount(totalCount);
      setTotalPages(totalPages);
      setdataLoading(false);
    }
  }, [
    customerData,
    customerLoading,
    customerError,
    paginationModel.page,
    paginationModel.pageSize,
    selectedTab,
  ]);

  useEffect(() => {
    voucherRefetch();
  }, [selectedTab]);
  const handlePaginationChange = (newModel: any) => {
    setPaginationModel((prevModel) => ({
      ...prevModel,
      ...newModel,
    }));
  };
  const handleSearchQueryChange = (newQuery: string) => {
    setSearchQuery(newQuery);
    if (newQuery.trim() === "") {
      voucherRefetch({ variables: { searchQuery: {} } });
    } else {
      voucherRefetch();
    }
  };

  const columns: GridColDef[] = [
    { field: "serial_no", headerName: "S.No", width: 100 },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      minWidth: 150,
    },
    { field: "loan_no", headerName: "Loan Number", flex: 1, minWidth: 250 },
    { field: "borrower_name", headerName: "Name", flex: 1, minWidth: 250 },
    {
      field: "address1",
      headerName: "Address",
      flex: 1,
      minWidth: 250,
    },
    { field: "loan_amt", headerName: "Amount", flex: 2, minWidth: 250 },

    {
      field: "interest_amount",
      headerName: "Interest Amount",
      flex: 2,
      minWidth: 250,
    },
  ];

  const columns1: GridColDef[] = [
    { field: "serial_no", headerName: "S.No", width: 100 },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      minWidth: 150,
    },
    { field: "loan_no", headerName: "Loan Number", flex: 1, minWidth: 250 },
    { field: "borrower_name", headerName: "Name", flex: 1, minWidth: 250 },
    {
      field: "address1",
      headerName: "Address",
      flex: 1,
      minWidth: 250,
    },
    { field: "loan_amt", headerName: "Amount", flex: 2, minWidth: 250 },
    {
      field: "daily_amount", 
      headerName: "Daily Amount",
      flex: 2,
      minWidth: 250,
      renderCell: (params) => {
        const dailyAmount = params.row.loan_amt / 100;
        return dailyAmount;
      },
    },
    {
      field: "interest_amount",
      headerName: "Interest Amount",
      flex: 2,
      minWidth: 250,
    },
  ];
 
  return (
    <div 
    style={{ 
         height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    }}
>
    <Typography
        style={{
            fontWeight: 400,
            fontFamily: "Roboto",
            fontSize: isSmallScreen ? "32px" : "48px",
            color: "black",
            textAlign: "center"
        }}
    >
        {TEXT_MESSAGES.TEXT_MEG}
    </Typography>
</div>
  );
};
export default Loanledger;
