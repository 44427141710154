import React from 'react';
import { Box, Button, Typography, useMediaQuery, useTheme, Grid } from '@mui/material';
import PheonixPaper from "../Components/PheonixPaper";
import themestyle from "../theme";
import { TEXT_MESSAGES } from '../constant';
import { useNavigate, useLocation } from 'react-router-dom';
import DetailRow from './DetailsRow';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import backarrow from "../assets/backarrow.svg"
const DepositorDetails: React.FC = () => {
  const location = useLocation();
  const depositor = location.state?.depositor;
  const isWideScreen = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const isMobileSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const navigate = useNavigate();
  const pagedetails = location.state?.paginationModel; 
  const handleBack = () => {
    navigate("/depositors",{state: { page: pagedetails}});
  };

  if (!depositor) {
    return (
      <Box>
        <Typography variant="h6">{TEXT_MESSAGES.No_Depositor}</Typography>
      </Box>
    );
  }

  return (
    <Box style={{ padding: "15px" }}>
      {isWideScreen ? (
        <PheonixPaper
          style={{
            borderRadius: "10px",
            height: "70px",
            marginBottom: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              textAlign: "center",
              padding: isMobileSmall ? "15px 0" : "15px 30px",
            }}
          >
            <Typography
              style={{
                color: themestyle.colors.black,
                fontWeight: themestyle.fontWeight.sm,
                fontSize: isMobileSmall
                  ? themestyle.fontSizes.sm
                  : themestyle.fontSizes.medium,
              }}
            >
              {TEXT_MESSAGES.DEPOSIOTR_DETAILS}
            </Typography>
            <div>
              <img src={backarrow} alt='arrow' />
              <Button variant="text" onClick={handleBack} style={{ marginBottom: "10px", textTransform: "none",
                 fontSize: "14px", fontWeight: 500, color: "#208BEE", textDecoration: 'underline' }}>
                {TEXT_MESSAGES.BACK_TO}
              </Button>
            </div>
          </div>
        </PheonixPaper>
      ) : (
        <PheonixPaper
        style={{
      height: "50px",
          marginBottom: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent:"space-around",
            textAlign: "center",
            padding: isMobileSmall ? "10px 0" : "15px 30px",
              
          }}
        >
          <Typography
            style={{
              color: themestyle.colors.black,
         fontWeight: themestyle.fontWeight.sm,
              fontSize: isMobileSmall
                ? themestyle.fontSizes.sm
                : themestyle.fontSizes.medium,
            }}
          >
            {TEXT_MESSAGES.DEPOSIOTR}
          </Typography>
          <div style={{justifyContent:"right"}}>
          <img src={backarrow} alt='arrow' />
          <Button variant="text" onClick={handleBack} style={{ marginBottom: "10px", textTransform: "none", fontSize: "12px", 
          fontWeight: 500,
             color: "#208BEE", textDecoration: 'underline' }}>
            {TEXT_MESSAGES.BACK_TO}
          </Button>
          </div>
        </div>
        </PheonixPaper>
      )}

      <Box
        sx={{
          display: "flex",
          backgroundColor:themestyle.colors.white,
          marginBottom: "3px",
          padding: "20px 20px 20px 40px",
        }}
      >
        <Grid item xs={12}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>{TEXT_MESSAGES.DEPOSIOTR_DETAILS1}</Typography>
        </Grid>
      </Box>

      <Box
        style={{
          padding: "20px 20px 20px 50px",
          borderRadius: "8px",
          height:isMobileSmall? '50vh': isTablet? '50vh':'55vh',
          overflowY: "auto",
          backgroundColor: themestyle.colors.white,
        }}
      >
        <Grid container spacing={4}>
          <DetailRow label="Depositor Name" value={depositor.depositor_name} />
          <DetailRow label="Depositor ID" value={depositor.depositor_id} />
          <DetailRow label="Primary Number" value={depositor.phone1} />
          <DetailRow label="Secondary Number" value={depositor.phone2} />
          <DetailRow label="Deposit Amount" value={depositor.deposit_amount} />
          <DetailRow label="Interest Rate" value={`${depositor.interest_rate}%`} />
          <DetailRow label="Monthly Interest Paid" value={depositor.monthly_interest_paid} />
          <DetailRow label="Total Interest Paid" value={depositor.total_interest_paid} />
          <Grid item xs={12}>
            <Grid container spacing={2}>
          <DetailRow label="Remarks" value={depositor.remarks} />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default DepositorDetails;