import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Drawer,
  TextField,
  TextareaAutosize,
  Button,
  Grid,
  Box,
  IconButton,
  MenuItem,
  Autocomplete,
  Typography,
  InputAdornment,
} from "@mui/material";
import PheonixTextarea from "./PheonixTextArea";
import CloseIcon from "@mui/icons-material/Close";
import { TEXT_MESSAGES } from "../constant";
import themestyle from "../theme";
import { VoucherFormData } from "../Interfaces/VoucherFormdataProps";
import VoucherModalProps from "../Interfaces/VoucherModalProps";
import { useMediaQuery, useTheme } from "@mui/material";
import Export from "../assets/Export.png";
interface Borrower {
  id: string;
  borrower_id: string;
  borrower_name: string;
  address1: string;
  address2: string;
}

const VoucherModal: React.FC<VoucherModalProps> = ({
  open,
  onClose,
  onAdd,
  fields,
  formData,
  setFormData,
  fieldErrors,
  setFieldErrors,
  title,
  requiredFields,
  editable = false,
  borrowers,
}) => {
  const [isConfirmDisabled, setIsConfirmDisabled] = useState<boolean>(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [initialFormData, setInitialFormData] = useState<VoucherFormData>({
    ...formData,
  });
  const theme = useTheme();
  const isMobileSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [uploadedFileName, setUploadedFileName] = useState<string>("");

  useEffect(() => {
    if (open) {
      const updatedFormData = { ...formData };
      if (!updatedFormData["Interest Rate"]) {
        updatedFormData["Interest Rate"] = "3";
      }
      setInitialFormData(updatedFormData);
      setFormData(updatedFormData);
    }
  }, [open]);

  useEffect(() => {
    const hasErrors = Object.values(fieldErrors).some((error) => error);
    setIsButtonDisabled(hasErrors);
  }, [fieldErrors]);

  useEffect(() => {
    const isFormDataChanged =
      JSON.stringify(initialFormData) !== JSON.stringify(formData);

    setIsConfirmDisabled(!isFormDataChanged && !editable);
  }, [initialFormData, editable]);

  useEffect(() => {
    const requiredFields: (keyof VoucherFormData)[] = [
      "Borrower Name",
      "Loan Amount",
      "Loan Type",
      "Voucher No",
      "Loan No",
    ];
    const hasEmptyRequiredFields = requiredFields.some((key) => !formData[key]);
    setIsConfirmDisabled(hasEmptyRequiredFields);
  }, [requiredFields]);

  const handleChange = (label: keyof VoucherFormData, value: string) => {
    if (
      [
        "Loan Amount",
        "Voucher No",
        "Interest Rate",
        "Loan No",
        "Interest Amount",
      ].includes(label as string)
    ) {
      value = value.replace(/[^0-9.]/g, "");
    }
    setFormData((prevData: any) => ({ ...prevData, [label]: value }));
    validateField(label, value);
  };
  const handleBlur = (label: keyof VoucherFormData) => {
    const value = formData[label];
    if (label === "Interest Rate" && !value) {
      setInterestRate("3");
      setFormData((prevData: any) => ({ ...prevData, [label]: "3" }));
    }
    validateField(label, value);
  };
  const validateField = (label: keyof VoucherFormData, value: string) => {
    let error = "";
    const requiredFields: (keyof VoucherFormData)[] = [
      "Borrower Name",
      "Loan Amount",
      "Voucher No",
      "Loan No",
    ];

    if (!value && requiredFields.includes(label)) {
      error = `${label} is required`;
    } else if (
      (label === "Guarantee Primary Number" ||
        label === "Guarantee Secondary Number") &&
      value &&
      !/^\d{10}$/.test(value)
    ) {
      error = `${label} must be a 10-digit number without special characters`;
    } else if (label === "Guarantee Name" && /\d/.test(value)) {
      error = `${label} cannot contain numbers`;
    } else if (
      (label === "Guarantee Secondary Number" &&
        value &&
        value === formData["Guarantee Primary Number"]) ||
      (label === "Guarantee Primary Number" &&
        value &&
        value === formData["Guarantee Secondary Number"])
    ) {
      error = TEXT_MESSAGES.Primary_voucher;
    } else if (label === "Loan Amount" && value) {
      if (/[a-zA-Z]/.test(value)) {
        error = TEXT_MESSAGES.AMT_ERR1;
      } else if (/\./.test(value)) {
        error = TEXT_MESSAGES.AMT_ERR3;
      } else if (!/^\d+(\d{1,2})?$/.test(value)) {
        error = TEXT_MESSAGES.AMT_ERR2;
      }
    }
     else if (label === "Voucher No" && value) {
      if (!/^\d+$/.test(value)) {
        error = TEXT_MESSAGES.Vochr_no;
      }
    } else if (label === "Loan No" && value) {
      if (!/^\d+$/.test(value)) {
        error = TEXT_MESSAGES.Loan_no;
      }
    } else {
      error = "";
    }
    setFieldErrors((prevErrors) => ({ ...prevErrors, [label]: error }));
  };
  const [interestRate, setInterestRate] = useState(
    formData["Interest Rate"] || "3"
  );

  useEffect(() => {
    setInterestRate(formData["Interest Rate"] || "3");
  }, [formData]);

  const handleInterestRateChange = (value: string) => {
    setInterestRate(value);
    handleChange("Interest Rate" as keyof VoucherFormData, value);
  };
  const handleFocus = (label: keyof VoucherFormData) => {
    const value = formData[label];
    if (label === "Interest Rate" && value === "3") {
      setFormData((prevData) => ({ ...prevData, [label]: "" }));
    }
  };
  const handleBorrowerChange = (
    event: React.ChangeEvent<{}>,
    value: Borrower | null
  ) => {
    if (value) {
      setFormData((prevData) => ({
        ...prevData,
        "Borrower ID": value.borrower_id,
        "Borrower Name": value.borrower_name,
        "Borrower Address1": value.address1,
        "Borrower Address2": value.address2,
      }));
    }
  };
  const calculateInterestAmount = (
    loanAmount: number,
    interestRate: number,
    loanType: string
  ): string => {
    let interestAmount: number;
    if (loanType === "Monthly") {
      interestAmount = loanAmount * (interestRate / 100);
    } else {
      const monthlyInterest = loanAmount * (interestRate / 100);
      interestAmount = (monthlyInterest * 10) / 3;
    }
    return Math.round(interestAmount).toString();
  };
  const interestAmount = calculateInterestAmount(
    parseFloat(formData["Loan Amount"] as string) || 0,
    parseFloat(formData["Interest Rate"] as string) || 0,
    formData["Loan Type"] as string
  );

  localStorage.setItem("interestamount", interestAmount);
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: isMobileSmall ? "100%" : "700px",
          border: "none",
          boxShadow: 24,
        },
      }}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography
            variant="h4"
            style={{
              fontWeight: 700,
              fontSize: isMobileSmall ? "18px" : "24px",
              fontFamily: "Inter",
            }}
          >
            {title}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box component="form">
          <Grid container spacing={2}>
            {fields.map((field, index) => (
              <Grid
                item
                xs={12}
                sm={
                  [
                    "Borrower Name",
                    ,
                    "Borrower Address1",
                    "Borrower Address2",
                    "Loan Type",
                    "Guarantee Name",
                    "Document Details",
                    "Remarks",
                  ].includes(field.label)
                    ? 12
                    : 6
                }
                key={index}
              >
                {field.label === "Borrower Name" ? (
                  <Autocomplete
                    id="borrower-autocomplete"
                    options={borrowers}
                    getOptionLabel={(option) =>
                      option.borrower_name
                        ? `${option.borrower_id}-${option.borrower_name}`
                        : option.borrower_name
                    }
                    value={
                      borrowers.find(
                        (b) => b.borrower_name === formData["Borrower Name"]
                      ) || {
                        id: "",
                        borrower_id: formData["Borrower ID"],
                        borrower_name: formData["Borrower Name"],
                        address1: formData["Borrower Address1"],
                        address2: formData["Borrower Address2"],
                      }
                    }
                    onChange={handleBorrowerChange}
                    readOnly={editable}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Borrower Name *"
                        fullWidth
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: themestyle.colors.disabled,
                              height: "50px",
                              top: "4px",
                            },

                            "&.Mui-focused fieldset": {
                              borderColor: "#208BEE",
                              height: "50px",
                              top: "2px",
                            },
                          },
                          "& .MuiInputLabel-root": {
                            color: fieldErrors[
                              field.label as keyof VoucherFormData
                            ]
                              ? themestyle.colors.error
                              : themestyle.colors.Primary2,
                            top: "6px",

                            "&.Mui-focused": {
                              top: "8px",
                              color: fieldErrors[
                                field.label as keyof VoucherFormData
                              ]
                                ? themestyle.colors.error
                                : themestyle.colors.primary,
                            },
                          },
                        }}
                      />
                    )}
                  />
                ) : field.label === "Loan Type" ? (
                  <TextField
                    select
                    fullWidth
                    label="Loan Type *"
                    value={
                      formData[field.label as keyof VoucherFormData] as string
                    }
                    onChange={(e) =>
                      handleChange(
                        field.label as keyof VoucherFormData,
                        e.target.value
                      )
                    }
                    onBlur={() =>
                      handleBlur(field.label as keyof VoucherFormData)
                    }
                    error={!!fieldErrors[field.label as keyof VoucherFormData]}
                    helperText={
                      fieldErrors[field.label as keyof VoucherFormData]
                    }
                    variant="outlined"
                    style={{ marginTop: "5px" }}
                    InputProps={{
                      readOnly: editable,
                    }}
                  >
                    <MenuItem value="Daily">Daily</MenuItem>
                    <MenuItem value="Weekly">Weekly</MenuItem>
                    <MenuItem value="Monthly">Monthly</MenuItem>
                  </TextField>
                ) : field.label.includes("Address") ||
                  field.label.includes("Remarks") ? (
                  <PheonixTextarea
                    label={field.label}
                    value={
                      formData[field.label as keyof VoucherFormData] as string
                    }
                    onChange={(e) =>
                      handleChange(
                        field.label as keyof VoucherFormData,
                        e.target.value
                      )
                    }
                    onBlur={() =>
                      handleBlur(field.label as keyof VoucherFormData)
                    }
                    error={!!fieldErrors[field.label as keyof VoucherFormData]}
                    helperText={
                      fieldErrors[field.label as keyof VoucherFormData]
                    }
                    editable={
                      field.label.includes("Borrower Address1") ||
                      field.label.includes("Borrower Address2") 
                        ? true
                        : editable
                    }
                  />
                ) : (
                  <TextField
                    key={field.label}
                    fullWidth
                    label={
                      field.label === "Loan Amount" ||
                      field.label === "Interest Rate" ||
                      field.label === "Voucher No" ||
                      field.label === "Loan No" ||
                      field.label === "Interest Amount"
                        ? `${field.label} *`
                        : field.label
                    }
                    type={field.type}
                    variant="outlined"
                    value={
                      field.label === "Interest Amount"
                        ? interestAmount
                        : (formData[
                            field.label as keyof VoucherFormData
                          ] as string)
                    }
                    onChange={(e) =>
                      handleChange(
                        field.label as keyof VoucherFormData,
                        e.target.value
                      )
                    }
                    onBlur={() =>
                      handleBlur(field.label as keyof VoucherFormData)
                    }
                    onFocus={() =>
                      handleFocus(field.label as keyof VoucherFormData)
                    }
                    error={!!fieldErrors[field.label as keyof VoucherFormData]}
                    helperText={
                      fieldErrors[field.label as keyof VoucherFormData]
                    }
                    style={{ marginTop: "5px" }}
                    InputProps={{
                      readOnly:editable ,
                      endAdornment:
                        field.label === "Interest Rate" ? (
                          <InputAdornment
                            position="end"
                            sx={{
                              backgroundColor: theme.palette.primary.main,
                              fontSize: "14px",
                              color: "white",
                              width: "auto",
                              height: "100%",
                              padding: "16px 15px",
                              marginRight: "-13px",
                              "& .MuiTypography-root": {
                                color: "white !important",
                              },
                            }}
                          >
                            %
                          </InputAdornment>
                        ) : undefined,
                      sx:
                        field.label === "Interest Rate"
                          ? { textAlign: "right" }
                          : undefined,
                    }}
                  />
                )}
              </Grid>
            ))}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="outlined"
          style={{
            backgroundColor: !editable
              ? themestyle.colors.white
              : themestyle.colors.primary,
            color: !editable
              ? themestyle.colors.primary
              : themestyle.colors.white,
            fontWeight: 500,
            textTransform: "uppercase",
            fontSize: "14px",
            marginBottom: "15px",
            marginRight: "15px",
            padding: "6px 16px",
            width: "76px",
            height: "36px",
          }}
        >
          {TEXT_MESSAGES.CLOSE}
        </Button>
        {!editable && (
          <Button
            onClick={onAdd}
            disabled={(isConfirmDisabled || isButtonDisabled)}
            style={{
              backgroundColor: (isConfirmDisabled || isButtonDisabled) ? "lightgray" : "#208BEE",
              color: "#ffffff",
              fontWeight: 500,
              textTransform: "uppercase",
              fontSize: "14px",
              marginBottom: "15px",
              marginRight: "15px",
              padding: "6px 16px",
              width: "76px",
              height: "36px",
            }}
            variant="contained"
          >
            {TEXT_MESSAGES.SAVE}
          </Button>
        )}
      </DialogActions>
    </Drawer>
  );
};

export default VoucherModal;
